.audio_root {
  position: fixed;
  width: 100vw;
  background-color: var(--pro-light);
  z-index: 9;
  left: 0;
  bottom: 0;
  border-top: 1px solid var(--pro-outline-border);
  box-shadow: 0px -1px 24px 0px rgba(0, 0, 0, 5%);
  padding: 12px 30px;
  .tracker_wrpper{
    overflow: hidden;
  }
}

.player_outer_wraper {
  .player_control_wrap {
    max-width: 534px;
    width: 100%;
    video {
      display: none;
    }
  }
  .expand_icon {
    transform: rotate(45deg);
  }
  .btn_controls {
    --pro-button-hover-bg: transparent;
    min-width: 40px;
    padding: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--pro-nav-btn-link-hover);
      transform: scale(0.7);
      opacity: 0;
      transition: transform 0.2s, opacity 0.2s;
      will-change: transform, opacity;
      z-index: -1;
    }
    &.btn_play {
      padding: 0;
    }
    .play_fill {
      fill: var(--pro-dark);
      transition: fill 0.3s;
    }
    &:hover {
      &::before {
        transform: scale(1);
        opacity: 1;
      }
      .play_fill {
        fill: var(--pro-primary-active);
      }
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.input_container {
  display: flex;
  align-items: center;
  gap: 8px;
  .time {
    color: var(--pro-input-text);
    max-width: 60px;
    min-width: 60px; //FIXME aswin need to remove this
    overflow: hidden;
    text-align: left;
  }
}
.volume_containe {
  width: 40px;
  display: flex;
  gap: 5px;
  align-items: center;
  overflow: hidden;
  transition: width 0.25s;
  justify-content: flex-end;
  input {
    max-width: 60px;
    height: 6px;
  }
  &:hover {
    width: 105px;
  }
}

.audio_title_wrap {
  gap: 10px;
  max-width: 534px;
  .img_wrap {
    max-width: 45px;
    width: 100%;
    > div {
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .audio_title {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.video_control {
  position: relative;
  padding-bottom: 51.25%;
  --slider-thumb-bg: var(--pro-light);
  iframe,
  img,
  video {
    position: absolute;
    width: 100%;
    height: 100% !important;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
  }
  .input_container {
    position: absolute;
    bottom: 1.5rem;
    padding: 0 2rem;
    width: 100%;
    color: var(--pro-light);
    z-index: 3;
    transition: all 0.25s;
    will-change: opacity, transform;
    transform: translateY(var(--control-y, 10px));
    opacity: var(--control-opacity, 0);
  }
  //FIXME aswin need to remove this
  .time_wraper {
    min-width: 135px;
  }
  &.short_video {
    padding-bottom: 150%;
  }
  .time {
    color: var(--pro-light);
  }
  .slider_wrapper {
    --slider-track-bg: rgba(255, 255, 255, 0.32);
  }
  .video_btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: opacity 0.3s;
    will-change: opacity;
    opacity: var(--control-opacity, 0);
    .btn_play{
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        transition: transform 0.25s;
        will-change: transform;
      }
      &:hover svg{
        transform: scale(1.2);
      }
    }
  }
  &:hover{
    --control-opacity : 1;
    --control-y :0;
  }
}

.slider_wrapper {
  --slider-track-bg: #e5e7eb;
  position: relative;
  flex: 1;
  input {
    width: 100%;
    height: 6px;
    cursor: pointer;
    opacity: 0;
  }
  .tracker_wrpper {
    pointer-events: none;
    position: relative;
    height: 6px;
    width: 100%;
    background-color: var(--slider-track-bg);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 3px;
    .tracker_bar {
      width: 0%;
      height: 6px;
      background-color: var(--pro-primary);
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 3px;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: var(--slider-thumb-bg);
        top: 50%;
        right: 0;
        transform: translateY(-50%) translateX(50%);
      }
    }
  }
}
