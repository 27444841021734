.avatar_container {
    cursor: pointer;
    position: relative;
    z-index: 3;
    .img_wrap {
      max-width: 40px;
      min-width: 40px;
      flex: 1;
    }
  }

  .dashboardTable {
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .dashboardTable:hover {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
  }

  .no_padding {
    padding-top: 0 !important;
  }
  
  
  .reactSelectContainer{
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
