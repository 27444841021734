.modal_root{
    --pro-modal-width :1000px !important;
  } 


  .pro_table_wrap{
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    td{
        vertical-align: top;
    }
    .limited_lines{
        max-width: 600px;
    }
  }