
// $pro-input-focus-shadow-light : #660047a6;
// $pro-input-focus-shadow-dark : #660047a6;

// Light theme color variables
$pro-primary-light: #FA6B07;
$pro-primary-text-light: #ffffff;
$pro-primary-hover-light:#ed6200;
$pro-primary-active-light:#ed6200;
$pro-secondary-light: #f4f5f7;
$pro-secondary-hover-light:#f0f1f3;
$pro-secondary-active-light:#ebecf0;
$pro-success-light: #117872;
$pro-danger-light: #b32318;
$pro-light-light: #fff;
$pro-dark-light: #000;
$pro-outline-light: #fff;
$pro-outline-hover-light: #f8f9fb;
$pro-outline-active-light: #f4f5f7;
$pro-outline-border-light:#dfe3eb;
$pro-disable-text-light: #a5adba;
$pro-disable-bg-light: #f5f6f7;
$pro-input-text-light: #4c4c4c;
$pro-input-hover-bg-light: #fafbfc;
$pro-input-focus-shadow-light: #ff6900;
$pro-input-active-light: #7a869a;
$pro-avatar-remaining-bg-light: #dfe1e6;
$pro-nav-btn-link-hover-light: #f1712117;
$pro-check-icon-color-light: #A11D2B;
$pro-toggle-bg-light: #D2D1D4;
$pro-pagination-shadow-light : rgba(0, 0, 0, 0.1);
//shimmers
$pro-shimmer-bg-light: #f8f6f6;
$pro-shimmer-gradient-color1-light: #f6f7f8;
$pro-shimmer-gradient-color2-light: #edeef1;
$pro-shimmer-gradient-color3-light: #f6f7f8;
$pro-shimmer-gradient-color4-light: #f6f7f8;

// Dark theme color variables
$pro-primary-dark: #FA6B07;
$pro-primary-text-dark: #ffffff;
$pro-primary-hover-dark:#ed6200;
$pro-primary-active-dark:#ed6200;
$pro-secondary-dark: #4e4e4e;
$pro-secondary-hover-dark:#727272;
$pro-secondary-active-dark: #393939;
$pro-success-dark: #117872;
$pro-danger-dark: #b32318;
$pro-light-dark: #000;
$pro-dark-dark: #fff;
$pro-outline-dark: #000;
$pro-outline-hover-dark: #727272;
$pro-outline-active-dark: #393939;
$pro-outline-border-dark:#404040;
$pro-disable-text-dark: #a5adba;
$pro-disable-bg-dark: #f5f6f7;
$pro-input-text-dark: #ebeaea;
$pro-input-hover-bg-dark: #0a0a0a;
$pro-input-focus-shadow-dark: #ff6900;
$pro-input-active-dark: #7a869a;
$pro-avatar-remaining-bg-dark: #dfe1e6;
$pro-nav-btn-link-hover-dark: #fff4eb;
$pro-check-icon-color-dark: #A11D2B;
$pro-toggle-bg-dark: #D2D1D4;
$pro-pagination-shadow-dark : rgba(255, 255, 255, 0.1);
//shimmers
$pro-shimmer-bg-dark: #3a3a3a;
$pro-shimmer-gradient-color1-dark: #3a3a3a;
$pro-shimmer-gradient-color2-dark: #4a4a4a;
$pro-shimmer-gradient-color3-dark: #3a3a3a;
$pro-shimmer-gradient-color4-dark: #3a3a3a;


.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 16px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  }