.settings_drag_wrap{
    position: relative;
    >div{
        overflow-y: auto;
        height: calc(100vh - 130px );
    }

    .tab_wrapper{
        :global{
            .nav .nav-link .pro-btn{
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
                &.active{
                    color: var(--pro-primary);
                }
                &::after{
                    background-color: var(--pro-primary);
                }
                &:not(.active):hover{
                    background-color: transparent;
                    color: var(--pro-primary);
                }
            }
        }
    }

    
    .drag_list_wrap{
        position: relative;
    }
    .drag_list_wrap,.drag_wrap{
        position: relative;
        height: 100%;
    }
}


.drag_list_item{
    border:1px solid var(--pro-outline-border);
    padding: 11px 12px;
    background-color: var(--pro-light);
    cursor: pointer;
    transition: all 0.3s;

    & + .drag_list_item{
        margin-top: 10px;  
    }
    
    &.active{
        border-color: var(--pro-primary);
        outline: 1px solid var(--pro-primary);
        .platform_icon svg path{
            fill: var(--pro-primary);
        }
    }

    &:not(.active):hover{
        background-color: #ffb7841c;
    }
    .num_wrap{
        flex: 0 auto;
    }
    .img{
        max-width: 48px;
        position: relative;
        width: 100%;
        figure{
            overflow: hidden;
            border-radius: 4px;
        }
    }
    .dtls{
        position: relative;
        flex: auto;
        .sub_title{
            opacity: 0.8;
        }
    }
    .icon{
        background-image: url("data:image/svg+xml,%3Csvg width='5' height='4' viewBox='0 0 5 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='%238E96A5'/%3E%3Crect x='3' width='2' height='2' fill='%238E96A5'/%3E%3C/svg%3E%0A");
        width: 5px;
        height: 24px;
        display: inline-block;
    }
    .banner_img{
        max-width: 48px;
        position: relative;
        width: 100%;
        figure{
            overflow: hidden;
        }
    }
    .action_wrap{
        flex:  0 0 150px;
        gap: 20px;
        .dlt_btn{
            text-decoration: underline;
        }
    }
}

.position_wrap{
    background-color: var(--pro-secondary);
    border-radius: 4px;
}

.position_files_wrap{
    .img_wrap{
        border: 1px solid var(--pro-outline-border);
        padding: 12px;
        border-radius: 8px;
        margin-bottom: 30px;
    }
}

.nav_list{
    border-bottom: 1px solid var(--pro-outline-border);
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    li{
        display: block;
        padding: 0 0;
        position: relative;
        bottom: -1px;
        button{
            width: auto;
            color:#000;
            font-size: 14px;
            line-height: 1.2;
            padding: 10px 16px;
            border: none;
            transition: 0.18s color ease-in-out;
            position: relative;
            text-decoration: none;
            background-color: transparent;
            display: flex;
            align-items: center;
            border-radius: 4px 4px 0 0;
            &:hover {
                color: var(--pro-primary-hover);
                background-color:var(--pro-nav-btn-link-hover) ;
            }
            &::after{
                background-color: var(--pro-primary-hover) !important;
            }
            &.active,
            &:active {
                border: 1px solid var(--pro-outline-border);
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom: 0px;
                background-color: var(--pro-light);
                color: var(--pro-primary-hover);
                &::after{
                    transform: scaleX(1);
                    background-color: var(--pro-primary-hover);
                }
            }
            &.dark {
                color: var(--pro-dark);
                &:hover {
                color: var(--pro-primary);
                }
            }
            &:focus,&:active{
                outline: none;
            }
            &:after{
                content: "";
                width: calc(100% - 31px);
                position: absolute;
                bottom: 5px;
                right: 0;
                left: 0;
                margin: auto;
                height: 2px;
                background-color: var(--pro-input-text);
                transform: scaleX(0);
                transition: .3s transform ease-in-out;
            }
        }
    }
}

.search_wrap{
    position: relative;
    .search_title_wrap{
        position: relative;
        .search_title{
            font-size: 14px;
            font-weight: 600;
        }
        .search_icon{
            color: #000;
            font-size: 16px;
            padding: 5px;
            cursor: pointer;
            width: 30px;
            height: 30px;
            display: inline-block;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: all 0.25s;
            &:hover{
                background-color: var(--pro-nav-btn-link-hover);
            }
        }
    }
    .search_input_wrap{
        padding: 10px;
        box-shadow: 0 0 3px 0px rgba($color: #000000, $alpha: 0.2);
        background-color: #fff;
        position: absolute;
        top:100%;
        left:0;
        display: none;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all .3s ease-in;
        z-index: -1;
        border-radius: 4px;
        &.show{
            display: block;
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            z-index: 5;
        }

        // input{
        //     height: 30px;
        //     padding: 10px 15px;
        // }
        :global{
            .pro-icon{
                right: 18px !important;
            }
        }
    }
}