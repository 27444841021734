.avatar_container {
    cursor: pointer;
    position: relative;
    z-index: 3;
    .img_wrap {
      max-width: 40px;
      min-width: 40px;
      flex: 1;
    }
  }

  .dashboardTable {
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .dashboardTable:hover {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
  }

  .no_padding {
    padding-top: 0 !important;
  }
  
  
  .reactSelectContainer{
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }

  .thumbnail_wrap{

    .thumbnail_img{
      flex: 0 0 40px;
      max-width: 40px;
      min-width: 40px;
      border-radius: 4px;
      img{
        border-radius: 4px;
      }
    }
    .thumbnail_title{
      strong{
        display: block;
        font-size: 18px;
        line-height: 1.5;
      }
      span{
        display: block;
        font-size: 12px;
        line-height: 1.1;
      }
      
    }

  }
