.checkbox_wrapper{
    div{
        width: auto !important;
        max-width: 80%;
    }
    p{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* this make the change   */
        direction: rtl;
        width: 100%;
    }
}

.FormTabs_root{
    padding: 0 !important;
}