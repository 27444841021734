.root_image_inner {
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--pro-light);
}

.formTabs_root{
  padding-inline: 0 !important;
}
.loader_root{
  width: 100px;
  position: relative;
  &::after{
    position: absolute;
    width: 44px;
    height: 44px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    border: 1px solid #0000001a;
    border-radius: 50%;
    z-index: -1;
  }
  svg{
      width: 50px;
      height: 50px;
      circle{
        stroke-dasharray: 270px;
        stroke-dashoffset: var(--offset);
        stroke: hsl(var(--color, 120),100%, 50%);
      }
  }
}