.root{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
}
.textWrapper{
    width: 100%;
    flex: 1;
}
.textInner{
    width: 100%;
    max-width: 65.7778rem;
    p{
        opacity: .7;
    }
}
.item{
    max-width: 170px;
    padding-right: 17px;
    margin-right: 17px;
    border-right: 1px solid var(--pro-outline-border);
   &:first-child ~ .item{
    padding-left: 0;
   }
   &:last-child{
    border-right: 0px;
   }
}