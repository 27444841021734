
.container_root {
  width: 100%;
  display: flex;
  position: relative;
  border: 1px solid var(--pro-outline-border);
  background-color: var(--pro-light);
  border-radius: 5px;
  align-items: center;
  button{
    white-space: nowrap;
  }
}
.avatar_container {
  cursor: pointer;
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
  .tooltip_wrap{
     img{
      max-width: 18px;
      width: 100%;
     }
  }
}
// custom style for pricing



.specialization {
  text-transform: capitalize;
  span + span {
    margin-left: 16px;
  }
  span{
    &:first-child{
      width: 20%;
    }
    &:not(:first-child){
      width: 10%;
    }
  }
}



.dash_border{
  border-bottom: 1px dashed var(--pro-outline-border);
}

.table_wrap{
  tr td:first-child{
    width: 25%;
  }
}
.table_data{
  td p{
    max-width: 850px;
  }
}