.graph_box{
    @media (min-width:992px) {
        // width: 20%;
        // &.box_lg{
        //     width: 40%;
        // }
        width: 20%;
    }
    >div{
        height: 100%;
        justify-content: space-between;
    }
}
.tab_btn{
    position: relative;
    cursor: pointer;
    padding: 1px 2px;
    color: var(--pro-dark);
    transition: color 0.25s;
    font-size: 12px;
    font-weight: 500;
    &::after{
        position: absolute;
        content: "";
        bottom: -5px;
        width: 100%;
        background-color: var(--pro-primary);
        height: 2px;
        left: 0;
        opacity: 0;
    }
    &.active{
        color: var(--pro-primary);
        &::after{
            opacity: 1;
        }
    }
    &:hover{
        color: var(--pro-primary);
    }
}
.filter_wrap{
    --pro-gutter-x:10px;
    --pro-gutter-y:10px;
    :global{
        .btn-wrap{
            .pro-btn{
                width: 100%;
            }
        }
    }
}

.progress_bar{
    position: relative;
    margin-top: 46px;
    .status_info{
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .title{
            font-size: 12px;
            font-weight: 500;
            line-height: 14.32px;
            color: #000;
        }
        .percentage{
            font-size: 10px;
            font-weight: 500;
            line-height: 11.93px;
            color: rgba(0, 0, 0, 0.5);
        }

    }
    :global{
        .progress {
            display: flex;
            height: 4px;
            overflow: hidden;
            font-size: 12px;
            background-color:rgba(250, 107, 7, 0.1);
          }
          
          .progress-bar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #000;
            text-align: center;
            background-color: rgba(250, 107, 7, 0.5);
            // @include transition($progress-bar-transition);
          }
    }
    
}
.status_wrap{
    .progress_bar{
        &:first-child{
            margin-top: 40px;
        }
    }
}
.doughnut_chart_wrap{
    canvas{
      min-height: 260px;
      width: 100% !important; 
      height: 100% !important;
    }
  }
  .charts_container{
    min-height: 400px;
  }