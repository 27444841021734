// .dashboardCard_root{
//     > div{
//         cursor: pointer;
//     }
// }


.dashboardCard_root{
    @media (min-width: 1200px){
        width: 18%;
    }
}