.header{
    background-color: var(--pro-light);
    box-shadow: 0 0 4px 1px rgba(9,30,66,.13), 0 0 4px 0 rgba(9,30,66,.08);
    a > figure{
        min-width: 11.25rem;
        max-width: 11.25rem;
    }
    :global{
      .pro-container .nav {
        padding-left: 2rem;
        @media(max-width  :1399px){
          padding-left: 10px;
          .nav-link a{
            --pro-button-padding-x : 4px;
          }
        }
        
      }
        
    }
}
.MenuDrawer_container {
    :global{
      .nav-link .material-symbols-outlined{
        max-width: 22px;
      }
      .secondary .pro-btn.pro-btn-outline{
        top: 25px;
      }
    }
  }