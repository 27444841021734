.pro_editor_wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
    .pro_editor_main {
      padding: 10px;
      border: 1px solid var(--pro-outline-border);
      border-radius: 4px;
      :global {
        .public-DraftStyleDefault-block {
          margin: 0;
        }
        .DraftEditor-root {
          min-height: 560px;
          max-height: 560px;
          word-break: break-word;
          .public-DraftStyleDefault-ul,
          .public-DraftStyleDefault-ol {
            margin: 0;
            & + * {
              margin-top: 12px;
            }
          }
        }
      }
    }
    .pro_editor_toolbar {
      width: fit-content;
      border: 0;
      border-radius: 0;
      margin-bottom: 0;
      padding: 2px 16px;
      background-color: var(--pro-editor-toolbar-bg);
      // box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
      row-gap: 12px;
      >div{
        margin-bottom: 0;
      }
      :global {
        .rdw-option-wrapper {
          border: 0;
          background: transparent;
          min-width: 20px;
          width: 20px;
          position: relative;
          box-shadow: none !important;
          --scale: 0.7;
          z-index: 2;
          &::before {
            position: absolute;
            border-radius: 0;
            background-color: var(--pro-secondary-active);
            border-radius: 50%;
            content: "";
            height: 25px;
            width: 25px;
            left: 50%;
            top: 50%;
            transition: transform 0.2s ease-in, opacity 0.2s ease-in;
            transform: scale(var(--scale)) translate(-50%, -50%);
            opacity: 0;
            will-change: opacity, transform;
            z-index: -1;
          }
          &:hover::before,
          &.rdw-option-active::before {
            opacity: 1;
            --scale: 1;
          }
        }

        .rdw-dropdown-wrapper {
          position: relative;
          background-color: var(--pro-light);
          min-width: 100px;
          &:hover {
            background-color: var(--pro-light);
            box-shadow: none;
          }
          > a {
            color: var(--pro-dark);
            width: 100%;
            :global(.rdw-dropdown-carettoopen) {
              border-top-color: var(--pro-dark);
              position: unset;
              margin-inline-start: auto;
            }
            :global(.rdw-dropdown-carettoclose) {
              border-bottom-color: var(--pro-dark);
              position: unset;
              margin-inline-start: auto;
            }
          }
          .rdw-dropdown-optionwrapper {
            position: absolute;
            bottom: 100%;
            width: 100%;
            max-height: 150px;
            background-color: var(--pro-light);
            border-color: var(--pro-outline-border);
            &:hover {
              box-shadow: none;
            }
            li {
              min-height: auto;
              padding: 5px;
              font-size: 14px;
              justify-content: start;
            }
            .rdw-dropdownoption-active {
              background-color: var(--pro-primary);
              color: #ffffff; //no variables used bcause same color
            }
            .rdw-dropdownoption-highlighted:not(.rdw-dropdownoption-active) {
              background-color: var(--pro-input-option-hover-bg);
            }
          }
        }
        .rdw-colorpicker-modal{
          top: unset;
          bottom: 35px;
          background-color: var(--pro-light);
          border: 1px solid var(--pro-outline-border);
        }
      }
    }
  }
  [data-theme*="dark"] {
    .pro_editor_toolbar {
      :global(.rdw-option-wrapper img) {
        filter: invert(1);
      }
    }
  }
  .editor_container {
    display: flex;
    flex-direction: column;
    .error_text {
      color: var(--pro-danger);
      font-size: 12px;
      line-height: 1;
      margin-top: 10px;
    }
  }
  

  


  /* Optional: Provide a tooltip when hovering over the truncated text */
  // .limited_lines:hover {
  //   overflow: visible;
  // }

  .arabic_editor{
    .pro_editor_toolbar{
      direction: rtl;
    }
  }