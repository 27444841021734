@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/bootstrap.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/common.scss";
@import "./config";
:root,
[data-theme*="light"] {
  @include light-theme;
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-light};
  --pro-toggle-bg: #{$pro-toggle-bg-light};
  --pro-pagination-shadow: #{$pro-pagination-shadow-light};
  --pro-shimmer-bg: #{$pro-shimmer-bg-light};
  --pro-shimmer-gradient-color1: #{$pro-shimmer-gradient-color1-light};
  --pro-shimmer-gradient-color2: #{$pro-shimmer-gradient-color2-light};
  --pro-shimmer-gradient-color3: #{$pro-shimmer-gradient-color3-light};
  --pro-shimmer-gradient-color4: #{$pro-shimmer-gradient-color4-light};
}
[data-theme*="dark"] {
  @include dark-theme;
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-dark};
  --pro-toggle-bg: #{$pro-toggle-bg-dark};
  --pro-pagination-shadow: #{$pro-pagination-shadow-dark};
  --pro-shimmer-bg: #{$pro-shimmer-bg-dark};
  --pro-shimmer-gradient-color1: #{$pro-shimmer-gradient-color1-dark};
  --pro-shimmer-gradient-color2: #{$pro-shimmer-gradient-color2-dark};
  --pro-shimmer-gradient-color3: #{$pro-shimmer-gradient-color3-dark};
  --pro-shimmer-gradient-color4: #{$pro-shimmer-gradient-color4-dark};
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 3px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fafafa;
  }
  &::-webkit-scrollbar-thumb {
    // background-color: var(--pro-input-focus-shadow);
    background-color: #e2e2e2;
  }
  // scrollbar-width: thin;
}

body {
  font-size: $font-size-normal;
  line-height: $primary-line-height;
}
input,
select {
  appearance: none;
  -webkit-appearance: none;
  &:focus,
  &:active {
    outline: none;
  }
}
.wrapper {
  width: max-content;
  height: calc(100vh - 56px);
  position: sticky;
  top: 56px;
  &:empty{
    display: none;
  }
  > div {
    height: 100%;
  }
}
.wrapper-table {
  flex: 1;
  width: calc(100% - 260px);
  overflow: hidden;
  .pro-pagination {
    display: flex;
    justify-content: center;
  }
}
.pro-pagination-wrapper {
  z-index: 9;
  box-shadow: 0px 0px 4px 0px var(--pro-pagination-shadow);
  .pro-input .pro-input__menu {
    overflow: hidden;
  }
}
.wrapper-tables {
  width: 100%;
  overflow: hidden;
}

.pt-custom {
  padding-top: size(56);
}
.react-transform-component,
.react-transform-wrapper {
  width: 100% !important;
}

.ytp-small-redirect {
  display: none;
}

.form-check.form-switch {
  min-height: 22px;
  .form-check-input {
    width: 1.75rem;
    height: 1rem;
    border: 0 !important;
    background-size: 17px;
    background-color: var(--pro-toggle-bg);
    border-color: var(--pro-toggle-bg);
    margin-top: 3px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    &:checked {
      background-color: var(--pro-primary);
      border-color: var(--pro-primary);
      background-position: 11px center;
    }
  }
}
.position-relative {
  position: relative;
}
.pro-border {
  border: 1px solid var(--pro-outer-border);
}
.pro-input .pro-input__single-value {
  padding: 0;
}
.hide-icon-input {
  padding-right: 40px;
}

.p-re {
  position: relative;
  .input-wrap {
    position: relative;
  }
  .hide-icon {
    right: 0;
  }
}

.hide-icon {
  position: absolute;
  right: 0;
  top: 24px;
  border: none;
  background-color: transparent;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--pro-dark);
}

.pro-h-100 {
  height: 100%;
}

.wrapper:empty + .wrapper-table .pro-pagination-wrapper {
  width: 100%;
}

.app-loading {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  &::after {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pro-primary);
    border-radius: 50%;
    overflow: hidden;
    border-bottom-color: transparent;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: rotation 0.6s infinite linear;
  }
  &.fill {
    position: fixed;
  }
  &.absolute {
    position: absolute;
  }
}
@keyframes rotation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.shimmer {
  background: var(--pro-shimmer-bg);
  background-image: linear-gradient(
    to right,
    var(--pro-shimmer-gradient-color1) 0%,
    var(--pro-shimmer-gradient-color2) 20%,
    var(--pro-shimmer-gradient-color3) 40%,
    var(--pro-shimmer-gradient-color4) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: shimmer;
  -webkit-animation-name: shimmer;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  &.letter {
    width: 100%;
    height: 15px;
    &.sub {
      width: 90%;
    }
    &.para {
      height: 6px;
    }
  }
  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.pro-text-primary {
  color: var(--pro-primary);
}

textarea.pro-input {
  min-height: 40px;
}

.input-country-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  .pro-input {
    transition: 0.18s all ease-in-out, padding 0s;
  }
  .input-main-wrap {
    position: relative;
    width: 100%;
    .code-wrap {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.34375rem 0.4375rem;
      font-size: 0.85rem;
      max-width: 84px;
      cursor: pointer;
      user-select: none;
      &::after {
        position: absolute;
        content: "";
        width: 1px;
        top: 5px;
        bottom: 5px;
        right: 0;
        background-color: var(--pro-border-color);
      }
      .country-select {
        .pro-input__control {
          background-color: inherit;
          .pro-input__single-value {
            color: var(--pro-dark);
          }
        }
        .pro-input__menu {
          background-color: var(--pro-light);
        }
      }
      .pro-input__control {
        border: 0;
        box-shadow: none !important;
      }
      .pro-input__value-container {
        padding: 0;
      }
      .pro-input__indicator-separator {
        display: none;
      }
      .pro-input__placeholder {
        font-size: 14px;
        color: var(--pro-dark);
      }
      .pro-input__indicator {
        padding: 4px;
      }
      .pro-input__menu-list {
        max-height: 250px;
      }
      .pro-input__menu {
        width: max-content;
        max-width: 300px;
      }
    }
    .pro-input {
      padding-left: 6rem;
    }
    &.verify-btn-wrap .pro-input {
      padding-right: 5rem;
    }
  }
  .error-text {
    font-size: 12px;
    line-height: $secondary-line-height;
    margin-top: 10px;
    color: var(--pro-danger);
  }
}

.pro-opacity-60 {
  opacity: 0.6;
}

.loader {
  text-align: center;
}
.ball-pulse > div {
  background-color: #fa6b07;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 3px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

.ball-pulse > div:nth-child(1) {
  -webkit-animation: scale 1.8s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: scale 1.8s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.ball-pulse > div:nth-child(2) {
  -webkit-animation: scale 1.8s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: scale 1.8s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.ball-pulse > div:nth-child(3) {
  -webkit-animation: scale 1.8s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: scale 1.8s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.nav{
  .nav-link{
    .pro-btn-link{
      &.active{
        color: var(--pro-primary-hover);
      }
      
    }
  }
}
.nav-2{
  .pro-btn-link{
    border: 1px solid transparent;
    border-bottom: 0;
    &::after{
      background-color: var(--pro-primary-hover);
      color: var(--pro-primary-hover);
    }
    &:hover,&.active,&:active,&:focus{
      color: var(--pro-primary-hover);
      // &:after{
      //   background-color: var(--pro-primary-hover);
      //   color: var(--pro-primary-hover);
      // }
    }
   
  }
}
.pro-input .pro-input__multi-value{
  margin: 0 4px 4px 0;
  .pro-input__multi-value__label{
    font-size: 11px;
  }
} 
.pro-ttl{
  &.h4,&.h5,&.h6{
    font-weight: 600;
  }
}
.pro-nowrap{
  white-space: nowrap;
}

.pro-min-xs{
  min-width: 100px;
}
.pro-min-sm{
  min-width: 200px;
}
.pro-min-md{
  min-width: 300px;
}
.pro-min-lg{
  min-width: 400px;
}
.pro-min-xl{
  min-width: 500px;
}

.pro-table .pro-check-box:empty{
  height: 20px;
}

.pro-input ~ .pro-icon .material-symbols-outlined{
  font-weight: 700;
}

.offcanvas_w_80{
  --pro-offcanvas-width:80%;
  .offcanvas-body{
    background-color: #f7f7f7;
  }
  .pro-table{
    background-color: #fff;
  }
}
.report-w-100{
  .col-12{
    max-width: 100%;
    .row{
      max-width: 100%;
      width: 100%;
      .col{
        max-width: 100%;
      }
    }
  }
}
/* App.css */
.custom-tooltip .tooltip-inner {
  background-color: white !important;
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}



.custom-tooltip{
  overflow: visible !important;
  max-width: 250px;
  min-width: 150px;
  border-radius: 4px;
  filter: drop-shadow(0px 0px 1px #dbdbdb34);
  .tooltip-arrow{
    background-color: black;
    z-index: 1;
    overflow: visible !important;
     &:after {
      content:'';
      position: absolute;
      display:block;
      width:12px;
      height: 12px;
      transform: rotate(45deg);
      background-color: #fff;
      border-style:solid;
      border-color:transparent;
      border-width:1px;
      z-index: 1;
    }
  }
  .tooltip-inner{
    padding: 10px 15px;
    border-radius: 4px;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    box-shadow: none !important;
    background-color: white !important;
    color: black !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    h6{
      display: block;
      margin-bottom: 5px;
      font-size:15px;
      font-weight: 500;
    }
    p{
      margin: 0;
      font-size: 13px;
      font-weight: 400;
    }
  }
  &.bs-tooltip-top{
    .tooltip-arrow{
      bottom:6px;
      &::after{
        border-bottom-color: rgba(0, 0, 0, 0.1);
        border-right-color: rgba(0, 0, 0, 0.1);
      }
    }
    
    .tooltip-inner{
      box-shadow: 0 -1px -3px 0 rgba(0, 0, 0, 0.2);
    }
  }
  
  &.bs-tooltip-bottom{
    .tooltip-arrow{
      top: -5px;
      &::after{
        border-top-color: rgba(0, 0, 0, 0.1);
        border-left-color: rgba(0, 0, 0, 0.1);
      }
    }
    
    .tooltip-inner{
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }
  }
  &.bs-tooltip-end{
    .tooltip-arrow{
      left: -5px;
      &::after{
        border-bottom-color: rgba(0, 0, 0, 0.1);
        border-left-color: rgba(0, 0, 0, 0.1);
      }
    }
    
    .tooltip-inner{
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }
  }
  &.bs-tooltip-start{
    .tooltip-arrow{
      right: 6px;
      &::after{
        border-top-color: rgba(0, 0, 0, 0.1);
        border-right-color: rgba(0, 0, 0, 0.1);
      }
    }
    
    .tooltip-inner{
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }
  }
  
}
.react-tooltip{
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  span{
    display: inline-block;
    h6{
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 6px;
    }
    p{
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  &.react-tooltip__place-top{
    .react-tooltip-arrow{
      bottom: -5px !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  &.react-tooltip__place-bottom{
    .react-tooltip-arrow{
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  &.react-tooltip__place-left{
    .react-tooltip-arrow{
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  &.react-tooltip__place-right{
    .react-tooltip-arrow{
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
  }
}
.react-tooltip,.react-tooltip-arrow{
  background:#fff !important;
  color:#000 !important;
 
}

.rs-picker-default .rs-picker-toggle{
  background-color: #fff;
}
.no-tb-edit .pro-btn-group{
  display: none;
}
.table-tab-wrap{
  overflow: hidden;
}
.table-tab-title-wrap{
  border: 1px solid var(--pro-outline-border);
  border-bottom: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  
}
.table-vscroll-wrap{
  &.maxH_300{
    .pro-table{
      tbody{
        max-height: 300;
      }
    }
  }
  &.maxH_200{
    .pro-table{
      tbody{
        max-height: 200;
      }
    }
  }
  .pro-table{
    border-radius: 0;
    border-bottom-left-radius:6px;
    border-bottom-right-radius:6px;
    thead{
      th{
        z-index: 10;
      }
    }
    tbody{
     display: block;
      max-height: 500px;
      overflow-y: auto;
      //  margin-right: -3px;
    }
    
    thead, tbody tr {
      display:table;
      width:100%;
      table-layout:fixed;
    } 
  }
}
.rs-picker-menu{
  z-index:10000 !important;
}

.custom-pro-table{
  thead{
    th{
      svg{
        color: var(--pro-outline-border);
        margin-top: -7px;
        position: absolute;
        right: 10px;
        top: 50%;
        transition: color .3s ease-in-out;
      }
      &:hover{
        svg{
          color: #000;
        }
      }
    }
  }
    
}
 
.card_listing{
  .card_col{
    > div{
      height: 100%;
    }
  }
}
.dashboard-offcanavas {
  .rs-picker-daterange{
    position: absolute;
    top: 20px;
    right: 50px;
    width: max-content !important;
  }
  .pro-table{
    th,td{
      &:first-child{
        width: 22%;
      }
    }
  } 
}