.root_image_inner {
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--pro-light);
}

.formTabs_root{
  padding-inline: 0 !important;
}