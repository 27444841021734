.root{
    :global(.dropdown) {
        width: auto;
        display: flex;
        button {
          background: none;
          border: none;
          &::after {
            display: none;
          }
        }
      }
      .profile_icon{
        color: var(--pro-dark);
      }
      .key_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        align-items: center;
      }
      .input_wrap {
        > span {
          pointer-events: none;
        }
      }
}