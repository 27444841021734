.root{
    padding: 0.9rem 0;
    color: var(--pro-dark);
    transition: all 0.25s;
    will-change: background-color, padding;
    &:hover{
        background-color: var(--pro-nav-btn-link-hover);
        padding: 0.9rem 0.5rem;
    }
    .img_wrap{
        max-width: 60px;
        width: 100%;
        .img{
            position: relative;
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
        }
        & + .content{
            margin-left: 1.45rem;
        }
    }
    .id_wrap{
        li{
            display: flex;
            align-items: center;
            gap: 2px;
            >span:first-child{
                color: var(--pro-primary);
            }
            ~ li{
                margin-left: 1rem;
            }
        }
    }
    ~ .root{
        border-top: 1px solid var(--pro-outline-border);
    }
}