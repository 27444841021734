.dashboard_wrap {
  padding: 0 28px;
}

.graph_box{
  @media (min-width:992px) {
    
      width: 23%;
  }
  >div{
      height: 100%;
      justify-content: space-between;
  }
}
.tab_btn{
  position: relative;
  cursor: pointer;
  padding: 1px 2px;
  color: var(--pro-dark);
  transition: color 0.25s;
  font-size: 12px;
  font-weight: 500;
  &::after{
      position: absolute;
      content: "";
      bottom: -5px;
      width: 100%;
      background-color: var(--pro-primary);
      height: 2px;
      left: 0;
      opacity: 0;
  }
  &.active{
      color: var(--pro-primary);
      &::after{
          opacity: 1;
      }
  }
  &:hover{
      color: var(--pro-primary);
  }
}
.thumb_list{
  position: relative;
  background-color: var(--pro-light);
  border: 1px solid var(--pro-outline-border);
  padding: 8px;
  border-radius: 4px;
  column-gap: 12px;
  &_img{
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    &::before{
      --pro-aspect-ratio: calc((40 / 40) * 100%);
    }
    img{
      object-fit: cover;
      object-position: top center;
    }
  }
  &_info{
    position: relative;
    padding-right: 30px;
    flex: 1;
    .title{
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
    .sub_title{
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
    .views{
      position: absolute;
      top:50%;
      right: 0;
      transform: translateY(-50%);
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

    }
  }

}
.list_wrap{
  max-height: 270px;
  overflow-y: auto;
}
.growth_percentage{
  position: relative;
  color: var(--color);
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  border-radius: 16px;
  width: max-content;
  &.increment{
    --color: var(--pro-success);
    --bg-color: #03AB651A;
  }
  &.decrement{
    --color: var(--pro-danger);
    --bg-color: #a11d2b1a;
    .arrow{
      transform: rotate(180deg);
    }
  }
}

.line_chart_info{
  position: relative;
  // margin-right: 56px;
  h3{
      font-size: 40px;
      font-weight:700 !important;
      line-height: 47px;
      margin-bottom: 5px;
  }
}
.line_chart_wrap{
  position: relative;
  flex: 1;
  // canvas{
  //    height: 100% !important;
  //    width: 100% !important;
  //   // min-height: 260px;
  // }
}
.curve_graph{
  max-height: 600px;
  min-height: 300px;
}
.progress_bar{
  position: relative;
  
}
.performance_root{
  justify-content: flex-start;
}
.doughnut_chart_wrap{
  canvas{
    min-height: 260px;
    width: 100% !important; 
    height: 100% !important;
  }
}

.charts_container{
  min-height: 700px;
}
.media_count_status{
  height: 100%;
  // cursor: pointer;
  &:hover{
    border-color: var(--pro-primary);
  }
  .top_wrap{
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    .inline_wrap{
      display: inline-block;
      max-width: max-content;
    }
    
  }
  .bottom_wrap{
    border-top: 1px solid var(--pro-outline-border);
    position: relative;
  }
  .count,  .total{
    display: block;
    // font-size: 2rem;
  }
  .category{
    display: inline-block;
  }
&.count_vertical{
  flex-direction: column;
  text-align: center;
  >h6{
    margin-bottom: 20px;
  }
}

}
@media screen and (max-width:1599px) {
  .tab_table_wrap{
    position: relative;
    flex-direction: column;
    :global{
      .col{
        margin-bottom: 15px;
      }
    }
  }
  
}

.card_col > div{
  height: 100%;
}
.shimmer_wrapper >div >div{
  height: 100%;
}